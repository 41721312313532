/* eslint-disable camelcase */

import { SanityContentLibrary } from '@guider-global/shared-types';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, LinearProgress } from '@mui/material';
import { Article, Video, PDF, Audio } from 'components/LearningHubItemPage';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  useSanityBaseLanguage,
  getContentItemBySlug,
} from '@guider-global/sanity-hooks';
import { useTrackEvent } from 'hooks/useTrackEvent';
import { getSubDomain } from '@guider-global/front-end-utils';

export const LearningHubItemPage: React.FC = () => {
  const { itemSlug } = useParams();

  const navigate = useNavigate();
  const organizationSlug = getSubDomain();

  const [contentLibraryItem, setContentLibraryItem] =
    useState<SanityContentLibrary | null>(null);

  const { getBaseLanguage } = useSanityBaseLanguage({ getSilently: true });

  const baseLanguage = getBaseLanguage();

  useTrackEvent({
    track: {
      eventKey: 'learning-hub-content-viewed',
      eventParams: {
        organization: { organizationSlug },
        learningHubContent: {
          organizationSlug,
          learningHubContentSlug: itemSlug as string,
        },
      },
    },
    if: itemSlug !== undefined,
    for: itemSlug,
  });

  useEffect(() => {
    const fetchContentFromSanity = async ({ slug }: { slug: string }) => {
      const response = await getContentItemBySlug({
        slug,
      });

      if (response.length === 0) {
        navigate('/learn');
      }

      setContentLibraryItem(response[0]);
    };

    if (!itemSlug) {
      navigate('/learn');
    } else {
      fetchContentFromSanity({
        slug: itemSlug,
      });
      if (contentLibraryItem?.metadata.type === 'link') {
        window.open();
        navigate('/learn');
      }
    }
  }, [itemSlug, contentLibraryItem?.metadata.type, navigate]);

  return (
    <Box sx={{ p: 2, backgroundColor: '#ffffff' }}>
      <Grid container columns={12}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Grid container columns={12}>
            <Grid item xs={12} md={6} sx={{ mx: 'auto' }}>
              <Button
                startIcon={<ArrowBack />}
                onClick={() => navigate(-1)}
                color="info"
                sx={{ textTransform: 'capitalize' }}
              >
                {baseLanguage.globals.common.go_back_button_label}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {!contentLibraryItem ? (
          <LinearProgress color="info" />
        ) : (
          <>
            {contentLibraryItem.metadata.type === 'article' && (
              <Article {...contentLibraryItem} />
            )}
            {contentLibraryItem.metadata.type === 'audio' && (
              <Audio {...contentLibraryItem} />
            )}
            {contentLibraryItem.metadata.type === 'link' && (
              <Navigate to={'/learn'} />
            )}
            {contentLibraryItem.metadata.type === 'pdf' && (
              <PDF {...contentLibraryItem} />
            )}
            {contentLibraryItem.metadata.type === 'video' && (
              <Video {...contentLibraryItem} />
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};
