import { Box, darken, lighten, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import { useMixpanelEvents, useMobileMediaQuery, useTrackEvent } from 'hooks';
import { RootState } from 'store';

import { PageWrapper } from 'components';
import Callout from 'components/LearningHub/Callout';
import TileGroup from 'components/LearningHub/TileGroup';
import { getSubDomain } from '@guider-global/front-end-utils';

export const LearningHubPage: React.FC = () => {
  const learningHubConfig = useSelector(
    (state: RootState) => state.sanityOrganization.organization.learning_hub,
  );

  const organizationSlug = getSubDomain();
  useTrackEvent({
    track: {
      eventKey: 'learning-hub-landing-page-visited',
      eventParams: {
        organization: { organizationSlug },
      },
    },
  });

  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  useMixpanelEvents({ navigationEventName: 'Learn' });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          minHeight: '80px',
          backgroundColor: `${lighten(theme.palette.info.main, 0.9)}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            width: isMobile ? '100vw' : '83vw',
            py: 2,
            px: isMobile ? 2 : 0,
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 600,
              color: theme.palette.info.dark,
              textAlign: 'center',
              pb: isMobile ? 0.5 : 0,
            }}
          >
            {learningHubConfig.welcome_message?.title ||
              'Welcome to the Learning Hub!'}
          </Typography>
          <Typography
            sx={{
              ml: isMobile ? 0 : 2,
              color: `${darken(theme.palette.info.main, 0.6)}`,
            }}
          >
            {learningHubConfig.welcome_message?.subtitle ||
              'We’re here to guide you through mentoring.'}
          </Typography>
        </Box>
      </Box>
      <PageWrapper sx={{ flexDirection: 'column', py: 0 }}>
        {learningHubConfig.layout.map((layoutItem, index) => {
          return (
            <Box key={`layout-item-${index}`} sx={{ width: '100%' }}>
              {layoutItem._type === 'learningHubCallout' && (
                <Callout {...layoutItem} />
              )}
              {layoutItem._type === 'learningHubTileGroup' && (
                <TileGroup {...layoutItem} />
              )}
            </Box>
          );
        })}
      </PageWrapper>
    </>
  );
};
