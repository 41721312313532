// External
import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import LegalNotices from 'components/legalNotices';

// Types
import { INavbarLink } from 'components/Navbar';

// Hooks
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { useAppDispatch } from 'store/hooks';
import { setNavbarUnauthenticatedActions } from 'store/slices/appSlice';

const LegalPagePrivacy: FC = () => {
  // Route
  const navigate = useNavigate();

  // Redux
  const dispatch = useAppDispatch();

  // Auth0
  const handleLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);
  const handleRegister = useCallback(
    () => navigate('/register/account'),
    [navigate],
  );

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const { legal_notices, navigation: baseLanguageNavigation } =
    getBaseLanguage();

  useEffect(() => {
    if (!baseLanguageNavigation) return;
    const organizationUnauthenticatedActions: INavbarLink[] = [
      {
        label: baseLanguageNavigation?.login_button_label,
        action: handleLogin,
        type: 'button',
        variant: 'outlined',
      },
      {
        label: baseLanguageNavigation?.register_button_label,
        action: handleRegister,
        type: 'button',
        variant: 'contained',
        color: 'info',
      },
    ];

    dispatch(
      setNavbarUnauthenticatedActions(organizationUnauthenticatedActions),
    );
  }, [baseLanguageNavigation, dispatch, handleLogin, handleRegister]);

  const {
    privacy_policy_content: privacyPolicyContent,
    privacy_policy_label: privacyPolicyLabel,
  } = legal_notices.privacy_policy;

  return (
    <>
      <LegalNotices title={privacyPolicyLabel} content={privacyPolicyContent} />
    </>
  );
};

export default LegalPagePrivacy;
